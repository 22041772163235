.awesome-iframe .widget-position-right.bubbleWithLabel .widgetLabel {
    right: 85px;
}
.awesome-iframe .bubbleWithLabel .widgetLabel {
    bottom: 26px;
}
.awesome-iframe .widgetLabel {
    bottom: 61px;
    transition-property: opacity;
    box-shadow: rgb(0 18 46 / 18%) 0px 2px 20px 0px;
}
.widget-position-right .widgetLabel {
    right: 100px;
}
.widgetLabel {
    position: absolute;
    height: 42px;
    bottom: 61px;
    z-index: 1;
    white-space: nowrap;
    font-size: 17px;
    line-height: 17px;
    border-radius: 30px;
    padding: 10px 15px;
    box-shadow: rgb(0 18 46 / 50%) 0px 6px 32px 0px;
    background: rgb(255, 255, 255);
    border: none !important;
    transition: all 0.2s ease-in-out 0s !important;
    color: #222 !important;
}
.widgetLabel:hover, #button-body:hover{
    background: #128C7E;
    border: none !important;
    color: #fff !important;
}
button {
    font-family: inherit;
    letter-spacing: -0.24px;
    -webkit-font-smoothing: antialiased;
    color: rgb(6, 19, 43);
}
#button {
    position: absolute;
    width: 112px;
    height: 140px;
    bottom: 12px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: none;
    z-index: 1;
    right: 0px;
}
.awesome-iframe .onlyBubble #button, .awesome-iframe .bubbleWithLabel #button {
    height: 94px;
    width: 94px;
    bottom: 0px;
}
#button-body {
    width: 60px;
    height: 60px;
    border-radius: 45%;
    display: inherit;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: initial;
    background-size: 130% 130%;
    transition: all 0.2s ease-in-out 0s;
    position: relative;
    color: rgb(0, 125, 252);
    border: none !important;
    background: #59CE72;
}
